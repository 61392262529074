.hiding-block {
  position: relative;
  transition: {
    property: opacity, transform;
    duration: .8s;
    delay: 1.1s;
  };

  &__content {
    pointer-events: none;
  }

  &__button {
    position: absolute;
    left: 0;
    bottom: 0;
    transition: {
      property: opacity, background-color;
      duration: .3s;
    };
  }

  &__form {

  }

  &:not(._open) {
    .recall-form {
      &__title, &__field, &__bottom {
        transform: translateY(15px);
        opacity: 0;
        transition: {
          duration: 0s;
          delay: 0s;
        };
      }
    }
  }

  &._open {
    .hiding-block {
      &__content {
        pointer-events: all;
      }

      &__button {
        opacity: 0;
        pointer-events: none;
      }
    }

    .recall-form {
      &__title, &__field, &__bottom {
        transform: translateY(0);
        opacity: 1;
        transition: {
          duration: .4s;
        };
      }
      &__title {
        transition-delay: .1s;
      }
      &__field {
        &:nth-child(1) {
          transition-delay: .2s;
        }
        &:nth-child(2) {
          transition-delay: .3s;
        }
      }
      &__bottom {
        transition-delay: .4s;
      }
    }
  }
}

@include respond-up('large') {
  .hiding-block {

  }
}

@include respond-up('medium') {
  .hiding-block {

    &__form {
      max-width: 384px;
    }
  }
}

@include respond('medium') {
  .hiding-block {

  }
}

@include respond-down('medium') {
  .hiding-block {

  }
}

@include respond-down('small') {
  .hiding-block {

  }
}