.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 50;

  &__inner {
  }

  &__logo {
    transition: {
      property: opacity, transform;
      duration: .8s;
      delay: .9s;
    };
    svg {
      width: 100%;
    }
  }

  &.need-animation {
    .header {
      &__logo {
        transition: {
          duration: 0s;
          delay: 0s;
        };
        transform: translateY(-10px);
        opacity: 0;
      }
    }
  }
}

@include respond-up('large') {
  .header {

    &__inner {
      padding-top: 42px;
    }
  }
}

@include respond-up('medium') {
  .header {
    &__logo {
      width: 223px;
    }
  }
}

@include respond('medium') {
  .header {
    &__inner {
      padding-top: 30px;
    }
  }
}

@include respond-down('medium') {
  .header {

  }
}

@include respond-down('small') {
  .header {
    &__inner {
      padding-top: 21px;
    }

    &__logo {
      width: 157px;
    }
  }
}