.image-slider {
  transition: {
    property: opacity, transform;
    duration: 1.3s;
  };

  &__picture {
    display: flex;
    z-index: 5;
    overflow: hidden;
    transition: width 1.5s cubic-bezier(0.25,0.1,0.25,1);
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1.5s cubic-bezier(0.25,0.1,0.25,1);
  }

  &__item-wrap {
    overflow: hidden;
    transition: width 1.5s cubic-bezier(0.25,0.1,0.25,1);
    position: relative;
  }

  &__item {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    transition: transform 1.3s cubic-bezier(0.25,0.1,0.25,1) !important;

    &.animate_normal {
      transform: translateX(3%);

      .image-slider {
        &__item-wrap {
          width: 100vw;
        }

        &__image {
          transform: scale(1.07);
        }
      }
    }

    &.animate_in {
      transform: translateX(0);
      z-index: 1;

      .image-slider {
        &__item-wrap {
          width: 100vw;
        }

        &__image {
          transform: scale(1);
        }
      }
    }

    &.animate_out {
      z-index: 2;
      transform: translateX(-3%);

      .image-slider {
        &__item-wrap {
          width: 0;
        }

        &__image {
          transform: scale(1.07);
        }
      }
    }
  }

  &.need-animation {
    transform: scale(1.05);
    opacity: 0;
    transition: {
      duration: 0s;
      delay: 0s;
    };
  }
}

@include respond-up('large') {
  .image-slider {

  }
}

@include respond-up('medium') {
  .image-slider {

  }
}

@include respond('medium') {
  .image-slider {

  }
}

@include respond-down('medium') {
  .image-slider {

  }
}

@include respond-down('small') {
  .image-slider {

  }
}