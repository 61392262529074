.hero-slider {
  position: relative;

  &__back {
    position: relative;
    z-index: 10;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
  }

  &__front {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 30;
  }

  &__front-inner {
  }

  &__image-slider {
    width: 100%;
    height: 100%;
  }

  &__text-slider {

  }
}

@include respond-up('large') {
  .hero-slider {

  }
}

@include respond-up('medium') {
  .hero-slider {

  }
}

@include respond('medium') {
  .hero-slider {

  }
}

@include respond-down('medium') {
  .hero-slider {

  }
}

@include respond-down('small') {
  .hero-slider {

  }
}