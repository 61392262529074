.index-page {
  overflow: hidden;
  width: 100%;
  height: 100%;

  &__back {
    position: relative;
    z-index: 20;
  }

  &__front {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 30;
  }

  &__content {
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__form {
  }

  &__footer {
    display: flex;
    transition: {
      property: opacity, transform;
      duration: .8s;
      delay: .9s;
    };
  }

  &__footer-text {
    font-size: 15px;
    line-height: 125%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: var(--primary-color);

    &:first-child {
      position: relative;
      padding-right: 10px;
      margin-right: 10px;

      &::before {
        content: '';
        position: absolute;
        right: 0;
        top: calc(50% - 5px);
        height: 10px;
        width: 1px;
        background-color: var(--primary-color);
      }
    }
  }

  &.need-animation {
    .index-page {
      &__footer {
        transition: {
          duration: 0s;
          delay: 0s;
        };
        opacity: 0;
        transform: translateY(10px);
      }
    }

    .hiding-block {
      transition: {
        duration: 0s;
        delay: 0s;
      };
      transform: translateY(10px);
      opacity: 0;
    }
  }
}

@include respond-up('large') {
  .index-page {
  }
}

@include respond-up('medium') {
  .index-page {
    &__footer {
      padding: 48px 0 54px;
    }
  }
}

@include respond('medium') {
  .index-page {

  }
}

@include respond-down('medium') {
  .index-page {

  }
}

@include respond-down('small') {
  .index-page {
    &__footer {
      padding: 34px 0 40px;
    }
  }
}