.text-slider {
  &__title-wrapper {
    overflow: hidden;
  }

  &__title {
    color: var(--color-yellow);
    font-weight: bold;
    line-height: 101%;
    letter-spacing: -0.01em;
    text-align: right;
    transition: transform .8s cubic-bezier(.25, .1, .25, 1);
    padding-right: 4px;
  }

  &__subtitle {
    color: white;
    line-height: 135%;
    text-transform: uppercase;
    text-align: right;
    transition: transform 1.1s, opacity .4s;
    transition-timing-function: cubic-bezier(.25, .1, .25, 1);
  }

  &__add-subtitle {
    display: inline;
    font-style: italic;
  }

  &__item {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &.tns-fadeIn {
      .text-slider {
        &__title {
          transform: translate3d(0, -6%, 0);
          transition-delay: .6s;
        }

        &__subtitle {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition-delay: 1s;
        }
      }
    }

    &.tns-fadeOut {
      opacity: 1;

      .text-slider {
        &__title {
          transform: translate3d(0, -110%, 0);
        }

        &__subtitle {
          opacity: 0;
          transform: translate3d(0, -20px, 0);
          transition-delay: .2s;
        }
      }
    }

    &.tns-normal {
      .text-slider {
        &__title {
          transform: translate3d(0, 100%, 0);
          transition-duration: 0s;
        }

        &__subtitle {
          opacity: 0;
          transform: translate3d(0, 20px, 0);
          transition-duration: 0s;
        }
      }
    }
  }

  &.need-animation {
    .text-slider {
      &__title {
        transform: translate3d(0, 100%, 0);
        transition: {
          duration: 0s;
          delay: 0s;
        };
      }

      &__subtitle {
        opacity: 0;
        transform: translate3d(0, 20px, 0);
        transition: {
          duration: 0s;
          delay: 0s;
        };
      }
    }
  }
}

@include respond-up('large') {
  .text-slider {
    &__item {
      padding-top: 176 / 946 * 100vh;
    }

    &__title {
      font-size: calc(min(170/1600*100vw, 170px));
    }

    &__subtitle {
      font-size: 19px;
    }

    &__add-subtitle {
      font-size: 13px;
    }
  }
}

@include respond-up('medium') {
  .text-slider {

    &__title-wrapper {
      margin-bottom: 25px;
    }
  }
}

@include respond('medium') {
  .text-slider {
    &__item {
      padding-top: 220 / 1024 * 100vh;
    }

    &__title {
      font-size: 100px;
    }

    &__subtitle {
      font-size: 15px;
    }

    &__add-subtitle {
      font-size: 12px;
    }
  }
}

@include respond-down('medium') {
  .text-slider {

  }
}

@include respond-down('small') {
  .text-slider {
    &__item {
      padding-top: 141 / 1024 * 100vh;
    }

    &__title {
      font-size: 50px;
    }

    &__subtitle {
      font-size: 13px;
    }

    &__title-wrapper {
      margin-bottom: 20px;
    }

    &__add-subtitle {
      font-size: 11px;
    }
  }
}

@include respond-down('x-small') {
  .text-slider {
    &__title {
      font-size: 40px;
    }
    &__title-wrapper {
      margin-bottom: 15px;
    }

    &__subtitle {
      font-size: 12px;
    }

    &__add-subtitle {
      font-size: 10px;
    }
  }
}