.container {
  max-width: var(--max-row-width);
  margin: 0 auto;

  &_spacer {
    padding: 0 var(--grid-spacer);
  }
}

@each $name, $data in $grid {
  @include respond-up($name) {
    .container {
      &_spacer-#{$name}-up {
        padding: 0 var(--grid-spacer);
      }
    }
  }

  @include respond-down($name) {
    .container {
      &_spacer-#{$name}-down {
        padding: 0 var(--grid-spacer);
      }
    }
  }
}