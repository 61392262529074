.recall-form {
  &__form {

  }
  &__success {
    display: flex;
    flex-direction: column;
  }

  &__success-icon {
    margin-bottom: 25px;
    transition-delay: .1s;
  }

  &__success-title {
    font-weight: 500;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: white;
    margin-bottom: 8px;
    transition-delay: .2s;

  }

  &__success-subtitle {
    line-height: 145%;
    font-size: 15px;
    color: rgba(254, 255, 255, 0.61);
    transition-delay: .3s;
  }

  &__success-icon, &__success-title, &__success-subtitle {
    transform: translateY(10px);
    opacity: 0;
    transition: {
      property: transform, opacity;
      duration: .3s;
    };
  }

  &__content {
    transition: {
      property: opacity;
      duration: .3s;
    };
  }

  &__title {
    font-weight: 500;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--primary-color);
  }

  &__fields {
    padding-bottom: 21px;
  }

  &__field {
    margin-bottom: 15px;
    position: relative;

    .errors {
      position: absolute;
      top: 100%;
    }
  }

  &__bottom {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
  }

  &__button {
    flex: 0 0 auto;

  }

  &__policy-wrapper {
    flex: 1 1 auto;
    margin-left: 31px;
    font-size: 12px;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: var(--white-opacity-50);
  }

  &__policy-link {
    color: var(--primary-color);
  }

  &__success {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: {
      property: opacity;
      duration: .3s;
    };
  }

  &__form {
    &.success {
      .recall-form {
        &__content {
          opacity: 0;
        }

        &__success {
          opacity: 1;
          pointer-events: all;
        }
        &__success-icon, &__success-title, &__success-subtitle {
          transform: translateY(0px);
          opacity: 1;
        }
      }
    }
  }
}

@include respond-up('large') {
  .recall-form {

  }
}

@include respond-up('medium') {
  .recall-form {

    &__title {
      font-size: 19px;
      margin-bottom: 25px;
    }

    &__success-title {
      font-size: 19px;
    }

    &__success-subtitle {
      font-size: 15px;
    }

    &__policy-wrapper {
      flex: 1 1 auto;
      margin-left: 31px;
      font-size: 12px;
      line-height: 125%;
      letter-spacing: -0.02em;
      color: var(--white-opacity-50);
      margin-right: -18px;
    }
  }
}

@include respond('medium') {
  .recall-form {

  }
}

@include respond-down('medium') {
  .recall-form {

  }
}

@include respond-down('small') {
  .recall-form {

    &__title {
      font-size: 16px;
      margin-bottom: 30px;
    }
  }
}

@include respond-down('x-small') {
  .recall-form {
    &__policy-wrapper {
      margin-left: 20px;
      font-size: 11px;
    }
    &__success-title {
      margin-bottom: 12px;
      font-size: 16px;
    }
  }
}