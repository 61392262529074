/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/
$spacer-smaller: 22;
$spacer-small: 22;
$spacer-medium: 40;
$spacer-large: 40;

$grid: (
  x-small: (
    from: 0,
    to: 350,
    spacer: 20
  ),
  small: (
    from: 0,
    to: 767,
    spacer: 20
  ),
  medium: (
    from: 768,
    to: 1279,
    spacer: 30
  ),
  large: (
    from: 1280,
    to: 1920,
    spacer: 50
  ),
  x-large: (
    from: 1921,
    spacer: 50
  )
);

:root {
  --max-row-width: 1500;

  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: "Montserrat", sans-serif;
  --h-font: "Montserrat", sans-serif;

  --t: transparent;

  --primary-color: white;
  --alert-color: #b50000;
  --default-transition: all 0.4s;
  --white-opacity-17: rgba(255, 255, 255, .17);
  --white-opacity-35: rgba(255, 255, 255, .35);
  --white-opacity-39: rgba(255, 255, 255, .39);
  --white-opacity-50: rgba(255, 255, 255, .5);
  --color-yellow: #FBBA00; //--желтый
  --color-bg: #2D4A4F;
}
