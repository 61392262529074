.button, button {
  margin: 0;
  text-align: center;
  transition: {
    property: background-color;
    duration: .4s;
  }
  display: inline-block;
  background-color: var(--white-opacity-17);
  border: 1px solid var(--white-opacity-39);
  cursor: pointer;
  text-decoration: none;
  border-radius: 50px;
  color: var(--primary-color);
  padding: 16px 32px;
  font-weight: 500;
  font-size: 15px;

  &:hover {
    background-color: var(--white-opacity-35);
  }
  &_expand, &_wide {
    width: 100%;
  }

  &_round {
    border-radius: 100px;
  }
}